import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";

import { STATUSES, PRIORITIES, OFFER_STATUSES } from "../../constants/Contants";
import {
  selectedWorkorder,
  allWorkorderList,
  currentTab,
} from "../../redux/slices/workorderSlice";
import {
  workorderList,
  updateWorkorder,
} from "../../redux/actions/workorderActions";
import { priorityList } from "../../redux/actions/priorityActions";

export default function ProjectListing() {
  const [workorders, setWorkorders] = useState([]);
  const [workordersWithoutOffers, setWorkordersWithoutOffers] = useState([])

  //search
  const [searchQuery, setSearchQuery] = useState("");
  const [statusQuery, setStatusQuery] = useState("");
  const [priorityQuery, setPriorityQuery] = useState("");
  const [searchableColumns, setSearchableColumns] = useState([
    { label: "Name", value: "aoname" },
    { label: "AO Number", value: "aonumber" },
    { label: "EO Number", value: "eonumber" },
    { label: "Contact Person", value: "contactPerson" },
    { label: "Address", value: "deliveryAddress" },
    { label: "Customer", value: "customer" },
    { label: "Priority", value: "priority" },
  ]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedColumnForSearch, setSelectedColumnForSearch] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const currentUser = useSelector((state) => state.userData.value);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNavigate = (row) => {
    dispatch(selectedWorkorder(row));
    navigate("/dashboard/add-work-order", { state: { workorderData: row } });
  };
  
  const handleDeleteWorkorder = async (workorder) => {
    const confirmed = window.confirm(
      t("are_you_sure_you_want_to_delete_this_workorder")
    );
    if (confirmed) {
      const payload = {
        isDeleted: true,
      };
      await updateWorkorder(workorder?._id, payload);
      await getWorkorderList();
      toast.success("workorder_deleted_successfully");
    }
  };

  const columns = [
    {
      name: <div className="table-content">{t("ao_number")}</div>,
      selector: (row) => (
        <div
          className="jobPosition cursor-pointer"
          onClick={() => {
            handleNavigate(row);
          }}
        >
          {row?.aonumber}
        </div>
      ),
    },
    {
      name: <div className="table-content">{t("ao_name")}</div>,
      selector: (row) => (
        <div
          className="jobPosition cursor-pointer"
          onClick={() => {
            handleNavigate(row);
          }}
        >
          {row?.aoname}
        </div>
      ),
    },
    {
      name: <div className="table-content">{t("customer")}</div>,
      selector: (row) => (
        <div
          className="jobPosition cursor-pointer"
          onClick={() => {
            handleNavigate(row);
          }}
        >
          {row.customer?.name}
        </div>
      ),
    },
    {
      name: <div className="table-content">{t("email")}</div>,
      selector: (row) => (
        <div
          className="jobPosition cursor-pointer"
          onClick={() => {
            handleNavigate(row);
          }}
        >
          {row.email}
        </div>
      ),
    },
    {
      name: <div className="table-content">{t("phone")}</div>,
      selector: (row) => (
        <div
          className="jobPosition cursor-pointer"
          onClick={() => {
            handleNavigate(row);
          }}
        >
          {row.phoneNumber}
        </div>
      ),
    },
    {
      name: <div className="table-content">{t("project")}</div>,
      selector: (row) => (
        <div
          className="jobPosition cursor-pointer"
          onClick={() => {
            handleNavigate(row);
          }}
        >
          {row.project?.name}
        </div>
      ),
    },
    {
      name: <div className="table-content">{t("status")}</div>,
      selector: (row) => (
        <div
          className="jobPosition cursor-pointer"
          onClick={() => {
            handleNavigate(row);
          }}
        >
          {row.status}
        </div>
      ),
    },
    {
      name: <div className="table-content">{t("priority")}</div>,
      selector: (row) => (
        <div
          className="jobPosition cursor-pointer"
          onClick={() => {
            handleNavigate(row);
          }}
        >
          {row.priority}
        </div>
      ),
    },
    {
      selector: (row) => (
        <div>
          <button
            className="simple-btn table-del-btn-min-w-20px"
            type="submit"
            onClick={() => handleDeleteWorkorder(row)}
          >
            <i className="fa fa-trash m-2 fs-5"></i>
          </button>
        </div>
      ),
    },
  ];

  const getWorkorderList = async () => {
    const workorderData = await workorderList(currentUser.clientId);
    if (workorderData?.status) {
      const initialFilteredData = workorderData?.data.filter(
        (workorder) =>
          workorder.status !== "New offer" &&
          workorder.status !== "Approved Offer" &&
          workorder.status !== "Denied Offer"
      );
      setWorkorders(workorderData?.data);
      setWorkordersWithoutOffers(initialFilteredData)
      setFilteredData(initialFilteredData);
      dispatch(allWorkorderList(workorderData?.data));
    }
  };
 
  const getPriorityList = async () => {
    const data = await priorityList();
    if (data?.status) {
      setPriorities(data?.data);
    }
  };

  useEffect(() => {
    getWorkorderList();
    getPriorityList();
    dispatch(currentTab("workOrderInfo"));
  }, []);

  //handling filteration of data
  useEffect(() => {
    if (searchQuery && !statusQuery && !priorityQuery) {
      if (selectedColumnForSearch === "customer") {
        const data = workorders?.filter((item) =>
          item[selectedColumnForSearch]?.name
            .toLowerCase()
            .includes(searchQuery?.toLowerCase())
        );
        setFilteredData(data);
      } else {
        const filteredData = workorders?.filter((item) =>
          item[selectedColumnForSearch]
            ?.toString()
            .toLowerCase()
            .includes(searchQuery?.toLowerCase())
        );
        setFilteredData(filteredData);
      }
    }
    if (statusQuery && !searchQuery) {
      if (statusQuery.toLowerCase() == "offer") {
        const statusData = workorders?.filter(
          (item) =>
            item.status.toLowerCase() === "new offer" ||
            item.status.toLowerCase() === "approved offer" ||
            item.status.toLowerCase() === "denied offer"
        );
        setFilteredData(statusData);
      } else {
        const statusData = workorders?.filter(
          (item) => item.status === statusQuery
        );
        setFilteredData(statusData);
      }
    }

    if (priorityQuery && !searchQuery) {
      const priorityData = workorders?.filter(
        (item) => item.priority === priorityQuery
      );
      setFilteredData(priorityData);
    }
    if (priorityQuery && statusQuery) {
      const priorityData = workorders?.filter(
        (item) => item.priority === priorityQuery && item.status === statusQuery
      );
      setFilteredData(priorityData);
    }
    if (searchQuery && statusQuery) {
      if (selectedColumnForSearch === "customer") {
        const data = workorders?.filter((item) =>
          item[selectedColumnForSearch]?.name
            .toLowerCase()
            .includes(searchQuery?.toLowerCase())
        );
        setFilteredData(data);
      } else {
        const data = workorders?.filter((item) =>
          item[selectedColumnForSearch]
            ?.toLowerCase()
            .includes(searchQuery?.toLowerCase())
        );
        setFilteredData(data);
      }
    }
    if(searchQuery === "" && statusQuery === "" && priorityQuery === "") {
      setFilteredData(workorders)
    }
  }, [selectedColumnForSearch, searchQuery, statusQuery, priorityQuery]);

  return (
    <div className="customListing">
      <div className="d-flex align-items-center justify-content-between gap-3 flex-wrap">
        <div className="heading">
          <h3>{t("work_orders")}</h3>
        </div>
        <div className="d-flex align-items-center ms-auto input_button">
          <div className="d-flex select-wrapper">
            <select
              onChange={(e) => {
                setStatusQuery(e.target.value);
              }}
              className="form-control"
            >
              <option key={"all"} value={""}>
              All workorders
              </option>
              {STATUSES?.map((c) => (
                <option key={c.label} value={c.value}>
                  {c.label}
                </option>
              ))}
            </select>
            <select
              onChange={(e) => {
                setStatusQuery(e.target.value);
              }}
              className="form-control"
            >
              <option key={"all"} value={"offer"}>
              All offers
              </option>
              {OFFER_STATUSES?.map((c) => (
                <option key={c.label} value={c.value}>
                  {c.label}
                </option>
              ))}
            </select>
            <select
              onChange={(e) => {
                setPriorityQuery(e.target.value);
              }}
              className="form-control"
            >
              <option key={"all"} value={""}>
              All priorities
              </option>
              {priorities?.map((c) => (
                <option key={c._id} value={c.name}>
                  {c.name}
                </option>
              ))}
            </select>
            <select
              onChange={(e) => {
                setSelectedColumnForSearch(e.target.value);
              }}
              className="form-control"
            >
              {searchableColumns?.map((c) => (
                <option key={c.label} value={c.value}>
                  {c.label}
                </option>
              ))}
            </select>
          </div>
          <form
            action=""
            className="header-search"
            onSubmit={(e) => e.preventDefault()}
          >
            <input
              type="text"
              value={searchQuery}
              placeholder={t("search")}
              onChange={(e) => setSearchQuery(e.target.value)}
            />

            <button>
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </form>
          <div style={{ display: "flex", gap: "10px" }}>
            {currentUser.clientId && (
              <Button
                className="simple-btn"
                onClick={() => (
                  navigate("/dashboard/add-work-order"),
                  dispatch(selectedWorkorder(null))
                )}
              >
                {t("add_workorder")}
              </Button>
            )}
            {currentUser.clientId && (
              <Button
                className="simple-btn"
                onClick={() => (
                  navigate("/dashboard/add-work-order", {state:{offer: true}}),
                  dispatch(selectedWorkorder(null))
                )}
              >
                {t("add_offer")}
              </Button>
            )}
          </div>
        </div>
      </div>

      <div className="table-responsive alternative-colors">
        <DataTable
          columns={columns}
          data={
            searchQuery || statusQuery || priorityQuery
              ? filteredData
              : workordersWithoutOffers
          }
          // data={filteredData}
          responsive={true}
          pagination
          paginationPerPage={40}
          paginationRowsPerPageOptions={[10, 20, 30, 40]}
        />
      </div>
    </div>
  );
}
