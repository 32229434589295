import React, {useEffect} from "react";
import { getStaffWorkSchedules } from "../../redux/actions/workScheduleActions";

const WorkScheduleComponent = () => {

    const handleGetStaffWorkSchedules = async() => {
        const data = await getStaffWorkSchedules({startDate: "", endDate: ""})
    }

    useEffect(() => {
       handleGetStaffWorkSchedules()
    },[])
    return(
        <>
        hello
        </>
    )
}

export default WorkScheduleComponent;